@import "./styles/global.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: $FontFam;
}

/* // set before and after border to none */
/* // set margin and padding to 0 */
/* // set box-sizing to border-box */

*::before,
*::after {
  box-sizing: inherit;
}
